<template>
    <div>
        <div class="top">
            <div class="title">消息中心</div>
        </div>
        <div class="news">
            <div class="news-list" v-for="(item, index) in list" :key="index">
                <div class="news-list-left">
                    <div class="news-list-title">{{ item.MsgTitle }}</div>
                    <div>
                        <span style="    color:#999999;">{{
                            item.MsgTime
                        }}</span>
                        <span class="clone" @click="remove(item)">
                            <i class="iconclose iconfont"></i>
                        </span>
                    </div>
                </div>
                <div class="info">{{ item.MsgContent }}</div>
                <div
                    class="info"
                    style="cursor: pointer"
                    @click="handleToPage(item)"
                >
                    查看详情
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getMyMsgList, removeMsg } from './service'

export default {
    data() {
        return {
            list: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        remove(data) {
            this.$confirm('你确定要执行吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeMsg({
                    ID: data.ID
                }).then(() => {
                    this.$message.success('删除成功')
                    this.init()
                })
            })
        },
        handleToPage(data) {
            switch (data.MsgType) {
                // 1退款审核失败 2退款审批通过 3支付成功 4订单关闭通知 5退款成功
                case 1:
                    window.open(
                        `/me/goodsDetails?OrderID=${data.BandID}`,
                        '_blank'
                    ).location
                    break
                case 2:
                    window.open(
                        `/me/goodsDetails?OrderID=${data.BandID}`,
                        '_blank'
                    ).location
                    break
                case 3:
                    window.open(
                        `/me/orderDetails?OrderID=${data.BandID}`,
                        '_blank'
                    ).location

                    break
                case 4:
                    window.open(
                        `/me/orderDetails?OrderID=${data.BandID}`,
                        '_blank'
                    ).location
                    break
                case 5:
                    window.open(
                        `/me/goodsDetails?OrderID=${data.BandID}`,
                        '_blank'
                    ).location
                    break
            }
        },
        init() {
            getMyMsgList({
                PageIndex: 1,
                PageSize: 100
            }).then(res => {
                this.list = res.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }
}
.news-list {
    border: 1px solid #f4f4f4;
    margin: 20px 0;
    border-radius: 5px;
    padding: 20px;

    .news-list-left {
        display: flex;
        justify-content: space-between;
    }

    .clone {
        margin-left: 20px;
        cursor: pointer;
        i {
            font-size: 8px;
        }
    }

    .news-list-title {
        font-family: 'ct';
    }

    .info {
        margin-top: 20px;
        color: #666666;
        font-size: 13px;
    }
}
</style>
