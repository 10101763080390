import { post } from '@/http'
// 我的消息中心列表
export function getMyMsgList(data) {
    return new Promise((resolve, reject) => {
        post('/MsgCenter/GetMyMsgList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 删除消息
export function removeMsg(data) {
    return new Promise((resolve, reject) => {
        post('/MsgCenter/RemoveMsg', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
